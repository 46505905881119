// Dependency
import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Button from "../../ui/button/button"
import Image from "../../ui/image/image"
import Link from "../../ui/link/link"
import Breadcrumbs from "../breadcrumbs/breadcrumbs"
import BlogListCard from "../blog-listing-card/blog-list-card"
import { useTranslation } from "react-i18next"

// Helper

// Styles
import "./blog-listing.scss"

// Template
const BlogListingComponent = props => {
  const [count, setCount] = useState(100)
  const { t } = useTranslation()
  const componentProps = props.props
  const query = useStaticQuery(graphql`
    query {
      story: allStoryblokEntry(
        filter: { field_component: { eq: "blogpost" } }
        sort: { fields: name }
      ) {
        nodes {
          name
          slug
          content
          full_slug
          created_at
          first_published_at
        }
      }
    }
  `)
  const lang = process.env.GATSBY_BUILD_LANGUAGE
  let filterLang = lang === "en" ? "default" : lang
  let data
  if (filterLang === "default") {
    data = query.story.nodes.filter(node => node.full_slug.startsWith("new-site/blog/"))
  } else {
    data = query.story.nodes.filter(node =>
      node.full_slug.includes(`${filterLang}/new-site`))
  }

  let blogs = []
  for (let i = 0; i < data.length; i++) {
    let fullSlug = data[i].full_slug.replace(
      process.env.GATSBY_BUILD_LANGUAGE,
      ""
    )
    fullSlug = fullSlug.replace("new-site/", "")
    if (fullSlug.startsWith("blog")) fullSlug = "/" + fullSlug
    blogs.push({
      content: JSON.parse(data[i].content),
      fullSlug: fullSlug,
      created_at: data[i].first_published_at,
      slug: data[i].slug,
    })
  }
  const heroBlog = blogs[0]
  const heroContent =
    heroBlog &&
    heroBlog.content &&
    heroBlog.content.content &&
    heroBlog.content.content.content[0] &&
    heroBlog.content.content.content[0].content &&
    heroBlog.content.content.content[0].content[0] &&
    heroBlog.content.content.content[0].content[0].text &&
    `${heroBlog.content.content.content[0].content[0].text.substring(
      0,
      140
    )}...`
  const blogData = blogs.slice(1).slice(0, count)
  console.log(blogData, "blogData")
  const showMore = count => {
    setCount(count + 3)
  }

  return (
    <section className="blog-listing" id="listing">
      <div className="listing__breadcrumbs">
        <div className="grid-container">
          <div className="grid__row">
            <div className="grid__col-xlarge-12 grid__col-large-12 grid__col-medium-9 grid__col-small-2">
              <Breadcrumbs
                breadTitle={t("blog.title")}
                large={true}
              />
            </div>
          </div>
        </div>
      </div>
      {/* {heroBlog && (
        <div className="blog-listing__hero">
          <div className="blog-listing__image">
            <Image
              largeScreenImage={heroBlog.content.largeScreenImage.filename}
              largeScreenHeight={440}
              largeScreenWidth={1400}
              smallScreenImage={heroBlog.content.smallScreenImage.filename}
              smallScreenHeight={269}
              smallScreenWidth={375}
              alt={heroBlog.content.largeScreenImage.alt}
              layout={"constrained"}
            />
          </div>
          <div className="grid-container">
            <div className="grid__row">
              <div className="grid__col-xlarge-12 grid__col-large-12 grid__col-medium-9 grid__col-small-2">
                <div className="blog-listing__content">
                  <div className="blog-listing__content__title">
                    {heroBlog.content.title}
                  </div>
                  <div className="blog-listing__content__description">
                    {heroContent}
                  </div>
                  <div className="blog-listing__content__link">
                    <Link
                      linkText={t("blog.readMore")}
                      asset="arrow-right"
                      textType="bold"
                      iconSize="small"
                      hoverEffect="anim1"
                      linkUrl={`${heroBlog.fullSlug}`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )} */}
      <div className="grid-container">
        <div className="grid__row">
          <div className="grid__col-xlarge-12 grid__col-large-12 grid__col-medium-9 grid__col-small-2">
            <div className="blog-listing__title">{componentProps.title}</div>
            <div className="grid__flex">
              {blogData.map((blog, index) => {
                if (!blog.content.hide)
                  return (
                    <div
                      key={index}
                      className="grid__col-xlarge-4 grid__col-large-4 grid__col-medium-9 grid__col-small-2"
                    >
                      <Link
                        linkText={<BlogListCard props={blog} />}
                        linkUrl={`${blog.fullSlug}`}
                      />
                    </div>
                  )
              })}
            </div>
          </div>
        </div>
      </div>
      {count < blogs.slice(1).length && (
        <div className="blog-listing__show-more-button">
          <Button
            buttonText={`${t("blog.showMore")} (${blogs.slice(1).length - count
              })`}
            onClick={() => showMore(count)}
          />
        </div>
      )}
    </section>
  )
}

export default BlogListingComponent
