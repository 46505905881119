// Dependency
import React from "react"
import { graphql } from "gatsby"
import { useStoryblokState } from "gatsby-source-storyblok"

// Components
import BlogListingComponent from "../components/general/blog-listing/blog-listing"

const BlogListing = ({ data }) => {
  let story = data.storyblokEntry
  story = useStoryblokState(story)
  return (
    <>
      <BlogListingComponent props={story.content} />
    </>
  )
}

export default BlogListing

export { Head } from "./page"

export const query = graphql`
  query BlogListingQuery($full_slug: String!) {
    storyblokEntry(full_slug: { eq: $full_slug }) {
      ...StoryEntry
    }
  }
`
