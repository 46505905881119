// Dependency
import React from "react"
import Image from "../../ui/image/image"

// Helper

// Styles
import "./blog-list-card.scss"

// Template
const BlogListCard = props => {
  const componentProps = props.props ? props.props : props
  return (
    <div className="blog-list-card" id="blog-list-card">
      <div className="blog-list-card__image">
        <Image
          largeScreenImage={componentProps.content.largeScreenImage.filename}
          largeScreenHeight={268}
          largeScreenWidth={416}
          smallScreenImage={componentProps.content.smallScreenImage.filename}
          smallScreenHeight={300}
          smallScreenWidth={416}
          alt={componentProps.content.largeScreenImage.alt}
          layout={"constrained"}
        />
      </div>
      <div className="blog-list-card__title">
        {componentProps.content.title}
      </div>
      {/* <div className="blog-list-card__date">
        {FormattedDate(componentProps.created_at)}
      </div> */}
    </div>
  )
}

export default BlogListCard
